import * as React from 'react';
import { useCart } from "react-use-cart";
import { useState } from 'react';
import { Link } from "gatsby";

const QuantityControl = ({ product, initalQuantity=1, type}) => {
  const { addItem, items, removeItem, updateItemQuantity } = useCart();
  const [quantity, setQuantity] = useState(initalQuantity);

  const handleChange = (e) => {
    e.preventDefault();
    const number = Number(e.target.value);
    if(number >= 0) setQuantity(number);
  };
  // Add item to cart button can be outside of the div to be smaller in width
  return (
    <div>
      <div className="row align-items-center">
        {type !== "cart" && (
          <div className="col-3">
            <p>Количество</p>
          </div>
        )}
        <div className="col-3">
          <button className="btn btn-secondary btn-sm text-white" onClick={() => {
            const number = quantity - 1;
            if(number >= 0) setQuantity(number);
            }
          }>-</button>
        </div>
        <div className={type === "cart" ? "col-6" : "col-3"}>
          <input type="number" value={quantity} className="form-control mx-0 px-0" onChange={handleChange} />
        </div>
        <div className="col-3">
          <button className="btn btn-secondary btn-sm text-white" onClick={() => setQuantity(quantity + 1)}>+</button>
        </div>
      </div>
      {type !== "cart" && (<button className="btn btn-secondary text-white mt-5" onClick={() => addItem(product, quantity)}><Link className="text-white text-decoration-none" to="/cart">Добави в количката</Link></button>)}
      {type === "cart" && (<button className="btn btn-secondary text-white mt-5" onClick={() => {
        const itemId = items.find(item => item.id === product.id).id;
        updateItemQuantity(itemId, quantity);
      }}>Потвърди</button>)}
    </div>
  );
}

export default QuantityControl;