import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { formatPrice } from '../utils';
import QuantityControl from '../components/quantityControl';
import SEO from '../components/seo';
import "../styles/productDetails.css";

const ProductDetails = ({ data }) => {
  const { product } = data;
  return (
    <Layout>
      <SEO title={product.product_name} description={product.description}/>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src={product.product_image_link} alt="product image" className="w-100" />
          </div>
          <div className="col-md-6">
            <h1>{product.product_name}</h1>
            <p>Марка: {product.brand}</p>
            <p>Кат.номер:<strong>{product.sku}</strong></p>
            <div className="cms-desc" dangerouslySetInnerHTML={{__html: product.description}}></div>
            <div className="card">
            <div className="card-body">
              <h5 className="card-title">{formatPrice(product.price)}</h5>
                <QuantityControl product={product} />
              </div>
          </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
query ($id: String) {
  product(id: {eq: $id}) {
    id
    product_name
    data_id
    description
    price
    brand
    sku
    product_image_link
    product_link
  }
}
`

export default ProductDetails;